import React from 'react';

import Governance from './governancePage/Governance';
import AboutPage from './aboutPage/AboutPage';
import VehicleList from './servicesPage/ServicesPage.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router';
import MainContent from './homePage/MainContent';
import NewsSection from './newsPage/NewsSection';
import ContactPage from './contactPage/ContactPage';
import HrLogin from './hrControls/HrLogin.jsx';
import { Toaster } from 'react-hot-toast';
import HrControls from './hrControls/HrControls.jsx';

function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path="/governance" element={<Governance />} />
          <Route path="/services" element={<VehicleList />} />
          <Route path="/news" element={<NewsSection />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/hrlogin" element={<HrLogin />} />
          <Route path="/hrcontrols" element={<HrControls/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
