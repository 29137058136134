import React from 'react'
import './Navbar.css'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <div className='NavbarBox'>
    <div className="logo">
        <img src="/Images/Logo/black.svg"></img>
    </div> 
       <div className="w-auto flexx gap-x-5">
        <NavLink className='no-underline text-black' to="/">Home</NavLink>
        <NavLink className='no-underline text-black' to="/about">About</NavLink>
        <NavLink className='no-underline text-black' to="/services">Services</NavLink>
        <NavLink className='no-underline text-black' to="/governance">Governance</NavLink>
        <NavLink className='no-underline text-black' to="/news">News</NavLink>
        <NavLink className='no-underline text-black' to="/contact">Contact</NavLink>
      </div>
    </div>
  )
}

export default Navbar